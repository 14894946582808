import payload_plugin_burnrYZg9s from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.34.6/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_A9SPJbIrab from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7xTam8Oysp from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RRcnwejjUu from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kNYDeAkzH1 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ncqedsql4C from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1xyq31rETJ from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eO70u5DKoH from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_L2NhhzG6HM from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.34.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_g3B0KKZXVA from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import customElements_client_71mjTDa34z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/customElements.client.ts";
import sentry_client_shVUlIjFLk from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/sentry.client.ts";
export default [
  payload_plugin_burnrYZg9s,
  revive_payload_client_A9SPJbIrab,
  unhead_7xTam8Oysp,
  router_RRcnwejjUu,
  payload_client_kNYDeAkzH1,
  navigation_repaint_client_ncqedsql4C,
  check_outdated_build_client_1xyq31rETJ,
  chunk_reload_client_eO70u5DKoH,
  plugin_vue3_L2NhhzG6HM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_g3B0KKZXVA,
  customElements_client_71mjTDa34z,
  sentry_client_shVUlIjFLk
]