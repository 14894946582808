<script setup lang="ts">
	import {computed, useSlots} from 'vue';
	import {type RouteLocationRaw, useRoute} from 'vue-router';
	import IconSvg from '~/components/generic/IconSvg.vue';

	const props = defineProps<{
		showBacklink: boolean,
		backRoute?: RouteLocationRaw,
		title: string,
		subtitle?: string
		compact?: boolean
	}>();

	const route = useRoute();

	const backRoute = computed(() => {
		if (!props.showBacklink) {
			return undefined;
		}

		if (props.backRoute) {
			return props.backRoute;
		}

		return route.path.endsWith('/') ? '../' : './';
	});

	const slots = useSlots();
	const hasButtons = computed(() => {
		return typeof slots.buttons !== 'undefined';
	});
</script>

<template>
	<div class="breadcrumb-header" :class="{ 'has-buttons': hasButtons }">
		<nav>
			<NuxtLink
					v-if="showBacklink"
					:to="backRoute"
					class="backlink"
			>
				<span class="sr-only">Terug</span>
				<IconSvg icon="arrow-left" />
			</NuxtLink>
			<div v-else class="backlink placeholder">
				<IconSvg icon="arrow-left" />
			</div>
			<div class="title">
				{{ title }}
			</div>
			<div v-if="!compact && subtitle" class="subtitle">
				{{ subtitle }}
			</div>
		</nav>
		<div v-if="typeof $slots.buttons !== 'undefined'" class="buttons">
			<slot name="buttons"></slot>
		</div>
	</div>
</template>

<style scoped>
	nav {
		margin: 0 0 var(--spacing-5);
	}

	.placeholder {
		visibility: hidden;
	}

	.backlink {
		display: inline-block;
		color: currentColor;
		margin: 0 0 8px;
	}

	.title {
		/* HEADER 5; */
		font-size: 1.25rem;
		line-height: 1.2;
		font-weight: bold;
	}

	.subtitle {
		font-size: var(--font-size-p-2);
		line-height: var(--line-height-p-2);
	}

	.breadcrumb-header.has-buttons {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 20px;

		.buttons {
			margin: 0 0 var(--spacing-5) auto;
			align-self: end;

			display: flex;
			justify-content: end;
			flex-wrap: wrap;
			gap: 10px 20px;
		}
	}

	@media screen and (max-width: 767px) {
		nav,
		.breadcrumb-header,
		.breadcrumb-header.has-buttons {
			display: none;
		}
	}
</style>
