import {abortNavigation, defineNuxtRouteMiddleware, navigateTo} from '#app';
import {pageNames} from '~/lib/pageHelper';
import {useCompaniesStore} from '~/store/company/companies';

export default defineNuxtRouteMiddleware(async (to) => {
	const companiesStore = useCompaniesStore();

	if (to.params.companyId && to.name !== pageNames.companyDetailEdit) {
		const companyId = Number(to.params.companyId);

		if (Number.isNaN(companyId) || companyId <= 0) {
			throw new Error('Het bedrijf ID is ongeldig');
		}

		if (companiesStore.companies.length === 0) {
			await companiesStore.fetchCompanies();
			if (companiesStore.companies.length === 0) {
				throw new Error('Geen bedrijven gevonden');
			}
		}

		const company = companiesStore.companies
			.find(company => company.companyId === companyId);

		if (!company) {
			throw new Error('Bedrijf niet gevonden');
		}

		const completedEntry = company.metadata.find(m => m.key === 'completed');

		// value is a string, so we need to compare it to 'false'
		// because java doesn't support union types and if you type it as java.lang.Object
		// it will be typed as Record<string, never>.
		if (completedEntry && completedEntry.value === 'false') {
			abortNavigation();
			return navigateTo({
				name: pageNames.companyDetailEdit,
				params: {companyId},
				query: {reason: 'company_details_incomplete', redirect: to.fullPath}
			});
		}
	}
});
