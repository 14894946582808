import {acceptHMRUpdate, defineStore} from 'pinia';
import {ref} from 'vue';
import {navigateTo} from '#app';
import useApi from '~/composables/useApi';
import type {ProblemDetail} from '~/lib/types/fetchErrors';
import type {CompanyCommand, CompanyOverviewDecorator} from '~/lib/types/companyTypes';
import {pageNames} from '~/lib/pageHelper';

export const useCompaniesStore = defineStore('companies', () => {
	const {GET, POST, PATCH} = useApi();

	const loading = ref(false);
	const companies = ref<CompanyOverviewDecorator[]>([]);
	const problemDetail = ref<ProblemDetail>();

	const updateCompanies = async (data: CompanyOverviewDecorator) => {
		const companyIndex = companies.value.findIndex((company) => company.companyId === data.companyId);
		if (companyIndex === -1) {
			companies.value.push(data);
		} else {
			companies.value[companyIndex] = data;
		}
	};

	const blockOrUnblockCompany = async (companyId: number, block: boolean) => {
		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await PATCH('/companies/{companyId}', {
			params: {
				path: {
					companyId: companyId.toString()
				}
			},
			body: block
		});

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		loading.value = false;
		return true;
	};

	const blockCompany = async (companyId: number) => {
		return blockOrUnblockCompany(companyId, true);
	};

	const unblockCompany = async (companyId: number) => {
		return blockOrUnblockCompany(companyId, false);
	};

	const fetchCompanies = async () => {
		loading.value = true;
		problemDetail.value = undefined;
		companies.value = [];

		const {data, error} = await GET(`/companies`);

		if (error) {
			problemDetail.value = error;
		}

		if (error || !data) {
			loading.value = false;
			return false;
		}

		companies.value = data;
		loading.value = false;
		return true;
	};

	const createCompany = async (submitData: CompanyCommand) => {
		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await POST('/companies', {
			body: submitData
		});

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		await updateCompanies(data);

		loading.value = false;

		navigateTo({
			name: pageNames.companyDashboard,
			params: {
				companyId: data.companyId
			}
		});

		return true;
	};

	return {
		blockCompany,
		unblockCompany,
		companies,
		fetchCompanies,
		createCompany,
		loading,
		problemDetail
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCompaniesStore, import.meta.hot));
}
