import {inject, onUnmounted, provide, ref, type Ref} from 'vue';
import type {NavigationGuard} from 'vue-router';
import {useConfirm} from '~/composables/useConfirm';

export type ConfirmLeave = {
	create(): Ref<boolean>
	// eslint-disable-next-line no-unused-vars
	register(tracker: () => boolean): void
	clear(): void
	hasUnsavedChanges(): boolean
}

const createContext = (): ConfirmLeave => {
	const trackers = new Set<() => boolean>();
	return {
		create: () => {
			const tracker = ref(false);
			trackers.add(() => tracker.value);
			return tracker;
		},
		register: (tracker: () => boolean) => {
			trackers.add(tracker);
		},
		clear: () => {
			trackers.clear();
		},
		hasUnsavedChanges: () => {
			let hasUnsavedChanges = false;
			for (const tracker of trackers) {
				hasUnsavedChanges = hasUnsavedChanges || tracker();
			}
			return hasUnsavedChanges;
		}
	};
};

export const useConfirmLeave = () => {
	const context = createContext();
	onUnmounted(() => {
		context.clear();
	});

	const parentContext = inject('confirmLeave') as ConfirmLeave | undefined;
	if (!parentContext) {
		throw new Error('useConfirmLeave must be used within a initialized tree');
	}
	parentContext.register(context.hasUnsavedChanges);

	provide('confirmLeave', context);

	return context;
};

export const initConfirmLeave = (
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-explicit-any
	registerUnloadHandler: (callback: (e: BeforeUnloadEvent) => any) => any,
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-explicit-any
	registerRouterHandler: (handler: NavigationGuard) => any
) => {
	const context = createContext();
	const message = 'Er zijn niet opgeslagen wijzigingen. Weet je zeker dat je de pagina wilt verlaten?';

	const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
		if (context.hasUnsavedChanges()) {
			event.preventDefault(); // Show the confirmation dialog in Firefox
			event.returnValue = message; // Chrome requires returnValue to be set
			return message;
		}
	};
	registerUnloadHandler(beforeUnloadHandler);

	const beforeRouteLeaveHandler: NavigationGuard = (to, from, next) => {
		if (context.hasUnsavedChanges()) {
			useConfirm(
				message,
				() => {
					context.clear();
					next();
				},
				() => {
					next(false);
				}
			);
		} else {
			next();
		}
	};
	registerRouterHandler(beforeRouteLeaveHandler);

	provide('confirmLeave', context);

	return context;
};
