import validate from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/auth.global.ts";
import company_45details_45global from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/company-details.global.ts";
import manifest_45route_45rule from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.2.3_eslint@9.21.0_jiti@2.4.2__io_rcl32f4brvpzk5nqv3qq4gk6gu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  company_45details_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  api: () => import("/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/middleware/api.ts")
}